<template>
  <v-app>
    <v-app-bar app>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="logo.img"
          transition="scale-transition"
          :width="logo.width"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
      <v-footer padless fixed>
        <v-col class="text-center" cols="12" >
          2022 — <strong v-on:click="verAviso" style="cursor:pointer"> Aviso de privacidad </strong>
        </v-col>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  data: () => ({}),
  methods: {
    verAviso(){
      window.open(this.logo.enlace, 'blank');
    }
  },
  computed: {
    ...mapState({ tipoCurso: "tipoCurso" }),
    logo() {
      switch (this.tipoCurso) {
        case 3: //Emociones 2
          return {
            enlace: "https://registro.englishlevel.com.mx/AvisodePrivacidad.html",
            img: "https://englishlevel.com.mx/images/logo.png",
            width: 50,
          };
        default:
          return {
            img: "",
            width: 0,
          };
      }
    },
  },
};
</script>
